import React, { createContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { ThemeProvider } from '@mui/material/styles';
import { Box, createTheme } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';
import Reducer from './Reducer';
import { Layout } from '@components';
import { environment, MicrocopyHelper, prepareRetailerName, prepareUrlLocale, retailers, SawListHelper } from '@helpers';
import { setLocale, setRetailer } from '@store/reducers/appSlice';
import {
    DefaultTheme,
    CandoTheme,
    GammaTheme,
    KarweiTheme,
    HuboTheme,
    ForesteaTheme,
    PraxisTheme,
    HornbachTheme,
    NoNameTheme,
    HuboNlTheme,
    EntrepotTheme,
    PraxisInsectsTheme,
    MrBricolageTheme,
    RaffitoTheme,
    FlexscreenInsectsTheme,
    BauhausTheme,
    KluswijsTheme,
    ThuisinTheme,
    WiahTheme
} from '@themes';
import { ThemeSpmConfigurator, ThemeWizard } from '@themes/base';
import { useRetailerLike, useIframeMessage } from '@hooks/common';

declare global {
    interface Window {
        iFrameResizer: any,
        parentIFrame: any,
        TSDAPIKEY: string,
    }
}

const initialState: any = {
    site: {},
    retailer: null,
    locale: null,
    microcopy: {},
    theme: {},
    channel: null,
    catalogState: {},
    windowHeight: null,
    retailerLogo: null,
    iframeMessage: null,
    parentUrl: null,
    catalogV2: false,
    retailerSettings: {},
};

export const themes = new Proxy({
        [retailers.Cando.name]: CandoTheme,
        [retailers.Gamma.name]: GammaTheme,
        [retailers.Karwei.name]: KarweiTheme,
        [retailers.Hubo.name]: HuboTheme,
        [retailers.Forestea.name]: ForesteaTheme,
        [retailers.Praxis.name]: PraxisTheme,
        [retailers.Brico.name]: PraxisTheme,
        [retailers.Hornbach.name]: HornbachTheme,
        [retailers.NoName.name]: NoNameTheme,
        [retailers.HuboNl.name]: HuboNlTheme,
        [retailers.HuboNlStairs.name]: HuboNlTheme,
        [retailers.Entrepot.name]: EntrepotTheme,

        [retailers.MrBricolageStairs.name]: MrBricolageTheme,
        [retailers.Raffito.name]: RaffitoTheme,
        [retailers.BauhausStairs.name]: BauhausTheme,
        [retailers.KluswijsStairs.name]: KluswijsTheme,
        [retailers.ThuisinStairs.name]: ThuisinTheme,
        [retailers.WiahStairs.name]: WiahTheme,

        // Insects
        [retailers.PraxisInsects.name]: PraxisInsectsTheme,
        [retailers.BricoInsects.name]: PraxisInsectsTheme,
        [retailers.FlexscreenInsects.name]: FlexscreenInsectsTheme,
    }, {
        get: (target, retailer, receiver) => {
            if (typeof retailer !== 'string')
                return Reflect.get(target, retailer, receiver);

            if (target[retailer])
                return target[retailer];

            const defaultRetailer = useRetailerLike(Object.keys(target), retailer);

            return defaultRetailer ? target[defaultRetailer] : DefaultTheme;
        },
    },
);

export const GlobalStateContext = createContext({});

const getTheme = (hasConfigurator: boolean) => {
    const
        retailerName = prepareRetailerName(initialState.retailer),
        extendTheme = (theme: any, extended: any) => {
            const extendedTheme = createTheme(theme, extended(theme));

            themes[retailerName] = extendedTheme;

            return extendedTheme;
        };

    let theme = themes[retailerName];

    if (!retailerName || !theme || !initialState.locale)
        return DefaultTheme;

    theme = extendTheme(theme, ThemeWizard);

    if (hasConfigurator)
        return extendTheme(theme, ThemeSpmConfigurator);

    return theme;
};

const isConfiguratorAvailable = (data) => {
    return !!data.configurator.nodes.find(item =>
        item.node_locale === prepareUrlLocale(initialState.locale)
        && item.retailers.find(item => item.name === initialState.retailer),
    );
};

export const GlobalStateProvider = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                ...siteMetaData
            }
            configurator: allContentfulConfigurator {
                nodes {
                    node_locale
                    retailers {
                        name
                    }
                }
            }
            microcopy: allContentfulResourceSet(
                filter: {
                    name: {
                        in: [
                            "catalog",
                            "pdp",
                            "sawlist",
                            "common",
                            "content_page",
                            "sample-service",
                            "configurator"
                        ]
                    }
                }
            ) {
                ...resourceNodes
            }
        }
    `);

    initialState.site = data.site.siteMetadata;
    initialState.retailer = children.props.pageContext.retailer;
    initialState.channel = children.props.pageContext.channel;
    initialState.locale = children.props.pageContext.locale;
    initialState.retailerLogo = children.props.pageContext?.retailerLogo;
    initialState.catalogV2 = false;
    initialState.retailerSettings = children.props.pageContext.retailerSettings;

    if (initialState.retailer)
        initialState.catalogV2 =
            Boolean(
                useRetailerLike([
                    retailers.Praxis.name,
                    retailers.Brico.name,
                    retailers.FlexscreenInsects.name,
                ], prepareRetailerName(initialState.retailer))
                || (environment !== 'production' && useRetailerLike(retailers.Hornbach.name, prepareRetailerName(initialState.retailer))),
            );

    const hasConfigurator = React.useMemo(
        () => initialState.locale && isConfiguratorAvailable(data),
        [ initialState.retailer, initialState.locale ]
    );

    initialState.theme = React.useMemo(() => getTheme(hasConfigurator), []);
    initialState.microcopy = React.useMemo(
        () => new MicrocopyHelper(data.microcopy.nodes, initialState.locale),
        [ initialState.locale ]
    );

    const [ storeId ] = useQueryParam('storeId', NumberParam);

    if (!global.micrositeStoreId && storeId)
        global.micrositeStoreId = storeId;

    const [ state, dispatch ] = useReducer(Reducer, initialState);

    const [ hornBachQueryParams ]: any = useQueryParams({
        articleId: StringParam,
        storeId: StringParam,
    });

    useEffect(() => {
        if (!state.retailer) return;

        const retailer = prepareRetailerName(state.retailer);

        dispatch({ type: 'SET_THEME', theme: themes[retailer] ?? DefaultTheme });

        if (
            environment === 'production'
            && retailer.includes('Hornbach')
            && retailer !== retailers.HornbachDoors.name
        )
            SawListHelper.checkHornbachQueryParams(retailer, hornBachQueryParams);
    }, [ state.retailer ]);

    useEffectOnce(() => {
        window.iFrameResizer = {
            onMessage: (message: any) => {
                dispatch({
                    type: message?.type === 'setParentUrl' ? 'SET_PARENT_URL' : 'SET_IFRAME_MESSAGE',
                    message,
                });
            },
        };

        import('iframe-resizer/js/iframeResizer.contentWindow');
    });

    // for migration to Redux
    const reduxDispatch = useDispatch();

    useMemo(() => {
        reduxDispatch(setRetailer(state.retailer));
        reduxDispatch(setLocale(state.locale));
    }, [ state.retailer, state.locale, reduxDispatch ]);

    const ref = useRef<HTMLDivElement>(null);

    return (
        <ReactResizeDetector
            targetRef={ ref }
            onResize={ () => dispatch({
                type: 'SET_WINDOW_HEIGHT',
                height: document.body.getBoundingClientRect().height,
            }) }
        >
            <GlobalStateContext.Provider value={ [ state, dispatch ] }>
                <ThemeProvider theme={ state.theme }>
                    <Layout { ...children.props }>
                        <Box ref={ ref }>{ children }</Box>
                    </Layout>
                </ThemeProvider>
            </GlobalStateContext.Provider>
        </ReactResizeDetector>
    );
};

const ReactResizeDetector = ({ targetRef, onResize, children }) => {
    useResizeDetector({ handleHeight: true, targetRef, onResize });

    return children;
};
