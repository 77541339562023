import React from 'react';
import { Divider, List } from '@mui/material';
import { find, omitBy, split, includes, isNil, every } from 'lodash';
import { useLocale, useTheme, useCatalogV2, useRetailerSettings } from '@hooks/common';
import { useActiveFilters, useRetailerFilters, useDimensions } from '@hooks/catalog';
import {
    mapFilterNameByLocale,
    trackOdinFormStep,
    priceFilterMappings,
    thicknessFilterMappings,
    FilterTypes,
    type Delta, dimensionFilterMappings,
} from '@helpers';
import {
    PriceFilter,
    DimensionsFilter,
    ThicknessFilter,
    AttributesFilter,
    ActiveFiltersFilter,
    FilterV2,
} from '@components/Catalog/Filters';
import DimensionsFilterV2 from '@components/Catalog/Filters/DimensionsFilterV2';
import Filter from '@components/Catalog/Filters/Filter';

const CatalogFilters = ({ filters, onChange, maxDimensions }) => {
    const
        locale = useLocale(),
        theme = useTheme(),
        catalogV2 = useCatalogV2(),
        retailerFilters = useRetailerFilters(),
        activeFilters = useActiveFilters(),
        { setDimensions } = useDimensions(),
        { showPrice } = useRetailerSettings();

    const [ filterValue, setFilterValue ] = React.useState<any>(null);

    // const handleFilterValueChange = (code: string, value: any) => catalogV2
    //     ? setFilterValue(value)
    //     : updateFilters(code, value);

    const handleFilterValueChange = (code: string, value: any) => updateFilters(code, value);

    const isEmpty = value =>
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0);

    const
        findFilterByName = (name: string) => find(filters, filter => filter.name === name),
        getActiveFilters = (filters: any = null) => omitBy(filters ?? activeFilters, (value, key) => isEmpty(value)),
        updateFilters = (code: string, values: any) => {
            let odinData: Delta[] = [ {
                field_name: code,
                from: activeFilters[code] ?? null,
                to: values[code],
            } ];

            switch (code) {
                case 'price':
                    odinData = [
                        {
                            field_name: 'price_from',
                            from: activeFilters['price']?.[0] ?? null,
                            to: values['price'][0] ?? null,
                        },
                        {
                            field_name: 'price_to',
                            from: activeFilters['price']?.[1] ?? null,
                            to: values['price'][1] ?? null,
                        },
                    ];
                    break;
                case 'dimension':
                    odinData = [
                        {
                            field_name: dimensionFilterMappings.max_length,
                            from: activeFilters[dimensionFilterMappings.max_length] ?? null,
                            to: values[dimensionFilterMappings.max_length],
                        },
                        {
                            field_name: dimensionFilterMappings.max_width,
                            from: activeFilters[dimensionFilterMappings.max_width] ?? null,
                            to: values[dimensionFilterMappings.max_width],
                        },
                    ];

                    if (catalogV2)
                        setDimensions({
                            length: values[dimensionFilterMappings.max_length],
                            width: values[dimensionFilterMappings.max_width],
                        });

                    break;
            }

            trackOdinFormStep(
                'change_form',
                'apply_filter',
                odinData,
                mapOdinFormValues(values),
            );

            onChange({ ...activeFilters, ...values });
        },
        resetFilters = (code: string) => {
            let
                resetValue = code ? { ...activeFilters, ...{ [code]: null } } : {},
                odinData: Delta[] = [ {
                    field_name: code,
                    from: activeFilters[code] ?? null,
                    to: null,
                } ];

            switch (code) {
                case 'dimension': // ToDo: check how to reset dynamically
                    resetValue = {
                        ...activeFilters, ...{
                            [dimensionFilterMappings.max_length]: null,
                            [dimensionFilterMappings.max_width]: null,
                        },
                    };

                    odinData = [
                        {
                            field_name: dimensionFilterMappings.max_length,
                            from: activeFilters[dimensionFilterMappings.max_length] ?? null,
                            to: null,
                        },
                        {
                            field_name: dimensionFilterMappings.max_width,
                            from: activeFilters[dimensionFilterMappings.max_width] ?? null,
                            to: null,
                        },
                    ];

                    setDimensions({ length: null, width: null });

                    break;
            }

            trackOdinFormStep(
                'change_form',
                'apply_filter',
                mapOdinDeltaValues(
                    code
                        ? odinData.filter(item => item.from !== item.to)
                        : Object.keys(activeFilters).map(key => ({
                            field_name: key,
                            from: activeFilters[key],
                            to: null,
                        })),
                ),
                code ? mapOdinFormValues(resetValue) : resetValue,
            );

            onChange(resetValue);
        },
        mapOdinFormValues = (filters: any) => {
            const result = omitBy({ ...activeFilters, ...filters }, value => isNil(value) || value === '');

            if (result.price) {
                result.price_from = result.price[0] ? (result.price[0] / 100) : null;
                result.price_to = result.price[1] ? (result.price[1] / 100) : null;
                delete result.price;
            }

            return omitBy(result, value => isNil(value));
        },
        mapOdinDeltaValues = filters => {
            filters.forEach((filter) => {
                if (filter.field_name === 'price') {
                    filters.push(
                        ...[
                            {
                                field_name: 'price_from',
                                from: filter.from[0] / 100,
                                to: null,
                            },
                            {
                                field_name: 'price_to',
                                from: filter.from[1] / 100,
                                to: null,
                            },
                        ].filter(item => item.from),
                    );
                }
            });

            return filters.filter(filter => filter.field_name !== 'price');
        };

    return (
        <List aria-label="filter-list">
            { !isEmpty(getActiveFilters()) &&
                <ActiveFiltersFilter
                    onReset={ filter => {
                        const resetValue = filter ? { ...activeFilters, ...{ [filter]: null } } : {};

                        trackOdinFormStep(
                            'change_form',
                            'apply_filter',
                            mapOdinDeltaValues(
                                filter
                                    ? [ {
                                        field_name: filter,
                                        from: activeFilters[filter],
                                        to: null,
                                    } ].filter(item => item.from !== item.to)
                                    : Object.keys(activeFilters).map(key => ({
                                        field_name: key,
                                        from: activeFilters[key],
                                        to: null,
                                    })),
                            ),
                            filter ? mapOdinFormValues(resetValue) : resetValue,
                        );

                        onChange(resetValue);
                    } }
                />
            }

            { retailerFilters.map(filter => {
                let
                    { filterCode, data } = mapFilterNameByLocale(filters, filter.code, locale),
                    children: any = [],
                    isSelected = !!activeFilters[filterCode];

                switch (filter.type) {
                    case FilterTypes.DIMENSION:
                        isSelected = !!activeFilters[dimensionFilterMappings.max_length]
                            || !!activeFilters[dimensionFilterMappings.max_width];

                        children.push(
                            catalogV2 ?
                                <DimensionsFilterV2
                                    key={ `grid_filter_${ filterCode }` }
                                    maxDimensions={ maxDimensions }
                                    onChange={ value => {
                                        handleFilterValueChange(
                                            filterCode,
                                            {
                                                [dimensionFilterMappings.max_length]: value?.length * 10,
                                                [dimensionFilterMappings.max_width]: value?.width * 10,
                                            },
                                        );
                                    } }
                                />
                                : <DimensionsFilter
                                    key={ `grid_filter_${ filterCode }` }
                                    maxDimensions={ maxDimensions }
                                    onChange={ value => {
                                        handleFilterValueChange(
                                            filterCode,
                                            {
                                                [dimensionFilterMappings.max_length]: value?.length * 10,
                                                [dimensionFilterMappings.max_width]: value?.width * 10,
                                            },
                                        );
                                    } }
                                />);

                        break;
                    case FilterTypes.THICKNESS:
                        const thicknessData = findFilterByName(thicknessFilterMappings.thickness);

                        if (!thicknessData?.values)
                            break;

                        thicknessData.values = thicknessData.values.filter(item => item.value);

                        if (!thicknessData.values.length)
                            break;

                        thicknessData.values.sort((a, b) => parseFloat(a.title) - parseFloat(b.title))
                        filterCode = thicknessFilterMappings.thickness;
                        isSelected = !!activeFilters[filterCode];

                        children.push(
                            catalogV2
                                ? <AttributesFilter
                                    key={ `grid_filter_${ filterCode }` }
                                    data={ thicknessData }
                                    onChange={ value => {
                                        handleFilterValueChange(filterCode, { [filterCode]: value });
                                    } }
                                />
                                : <ThicknessFilter
                                    key={ `grid_filter_${ filterCode }` }
                                    data={ thicknessData }
                                    onChange={ value => {
                                        handleFilterValueChange(
                                            filterCode,
                                            {
                                                [thicknessFilterMappings.thickness]: (includes(activeFilters?.[thicknessFilterMappings.thickness], value)
                                                    ? split(activeFilters?.[thicknessFilterMappings.thickness], ',').filter(item => item !== value)
                                                    : split(activeFilters?.[thicknessFilterMappings.thickness], ',').filter(item => item.length).concat(value))
                                                    .toString(),
                                            },
                                        );
                                    } }
                                />,
                        );

                        break;
                    case FilterTypes.PRICE:
                        if (!showPrice) break;

                        const
                            minPrice = findFilterByName(priceFilterMappings.min_price).values[0].value,
                            maxPrice = findFilterByName(priceFilterMappings.max_price).values[0].value;

                        filterCode = 'price';
                        isSelected = !!activeFilters[filterCode];

                        children.push(
                            <PriceFilter
                                key={ `grid_filter_${ filterCode }` }
                                min={ minPrice }
                                max={ maxPrice }
                                minValue={ activeFilters.price?.[0] }
                                maxValue={ activeFilters.price?.[1] }
                                onChange={ (min_price = undefined, max_price = undefined) => {
                                    handleFilterValueChange(filterCode, { price: [ min_price, max_price ] });
                                } }
                            />,
                        );

                        break;
                    case FilterTypes.ATTRIBUTE:
                        if (!data || isEmpty(data.values) || every(data.values, item => !item.value)) return;

                        children.push(
                            <AttributesFilter
                                key={ `grid_filter_attribute_${ filterCode }` }
                                data={ data }
                                onChange={ value => {
                                    handleFilterValueChange(filterCode, { [filterCode]: value });
                                } }
                            />,
                        );

                        break;
                }

                if (!children.length) return null;

                return catalogV2
                    ? <FilterV2
                        key={ `catalog_filter_${ filterCode }` }
                        isSelected={ isSelected }
                        content={ filter }
                        children={ children }
                        onApply={ () => updateFilters(filterCode, filterValue) }
                        onReset={ () => resetFilters(filterCode) }
                    />
                    : <Filter key={ `catalog_filter_${ filterCode }` } content={ filter } values={ children }/>;
            }) }

            <Divider sx={ { [theme.breakpoints.up('md')]: { display: 'none' } } }/>
        </List>
    );
};

export default CatalogFilters;
